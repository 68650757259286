import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux';
import {Link} from 'gatsby';

import SEO from '../components/seo'
import { tablet, desktop } from '../breakpoints';

//thumbs
import armin from '../images/artist_carousel/thumbs/armin-van-burren.png';
import adamBeyer from '../images/artist_carousel/thumbs/adam-beyer.png';
import chrisLiebing from '../images/artist_carousel/thumbs/chris-liebing.png';
import eatsEverything from '../images/artist_carousel/thumbs/eats-everything.png';
import erickMorillo from '../images/artist_carousel/thumbs/erick-morillo.png';
import johnDigweed from '../images/artist_carousel/thumbs/john-digweed.png';
import jorisVoorn from '../images/artist_carousel/thumbs/joris-voorn.png';
import markBrown from '../images/artist_carousel/thumbs/mark-brown.png';
import nicoleMoudaber from '../images/artist_carousel/thumbs/nicole-moudaber.png';
import paulOakenfold from '../images/artist_carousel/thumbs/paul-oakenfold.png';
import sanderDoorn from '../images/artist_carousel/thumbs/sander-doorn.png';
import tiesto from '../images/artist_carousel/thumbs/tiesto.png';
import ultra from '../images/artist_carousel/thumbs/ultra.jpg';
import spinnin from '../images/artist_carousel/thumbs/spinnin.jpg';
import djez from '../images/artist_carousel/thumbs/djez.jpg';
import jamiejones from '../images/artist_carousel/thumbs/jamiejones.jpg';
import musicalfreedom from '../images/artist_carousel/thumbs/musicalfree.jpg';
import absolute from '../images/artist_carousel/thumbs/absolute.jpg';
import amelielens from '../images/artist_carousel/thumbs/amelie-lens.jpg';
import djpaulette from '../images/artist_carousel/thumbs/dj-paulette.jpg';
import reallies from '../images/artist_carousel/thumbs/reallies.jpg';

class PoscastPage extends Component {

	constructor(){
		super();
		this.state = {}
	}

	render() { 

		return (
			<Page>

					<SEO title="Podcasts" keywords={[`radio`, `award winning`, `production`]} />

                    <Wrapper>
					<Title>Podcasts</Title>
					<Inner>
                    <div className="intro inner">
							<p>From a pioneering project for the NHS in 2005 to a series of interviews with some of the world’s most creative people with best selling author Susie Pearl  in 2020, podcasts are becoming an integral part of of our business.
</p>
                        </div>
                        </Inner>
				    </Wrapper>


					<svg className="duoFilter">
					<filter id="duotone_peachypink">
					<feColorMatrix
      type="matrix"
      values=" 2.000  0.841  0.692  0.000  0.000 
               0.000  1.630  0.209  0.000  0.000 
               1.000 -0.114  1.260  0.320  0.000 
              -0.114  0.886  1.260  0.595  0.000">
    </feColorMatrix>
						<feComponentTransfer color-interpolation-filters="sRGB" result="duotone">
						<feFuncR type="table" tableValues="0.827450980392157 1"></feFuncR>
						<feFuncG type="table" tableValues="0.462745098039216 1"></feFuncG>
						<feFuncB type="table" tableValues="0.474509803921569 1"></feFuncB>
						<feFuncA type="table" tableValues="0 1"></feFuncA>
						</feComponentTransfer> 
					</filter>        
					</svg>	


                    <Artists>
                        <li><Link to="/conversations" className="">Conversations with Susie Pearl</Link></li>
                        <li><Link to="https://shows.acast.com/the-quiet-carriage/episodes" className="">The Quiet Carriage</Link></li>
					</Artists>
				
			</Page>
		)

  }

}

function mapStateToProps(state){

}

export default connect(mapStateToProps, {  })(PoscastPage);


/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Page = styled.section`
	min-height:100%;

	.duoFilter {
		position:absolute;
		left:-999px;
	}
`

const Wrapper = styled.section`
	min-height:100%;
	padding: 150px 20px;
  	background: #FDF7F4;
`

const Artists = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin:0;
  padding:50px 0;
  height:100vh; 
  background: #c2b0bc;

  li {
    text-align:center;
    display:block;
    width:100%;
	font-size:4vw;
	margin-bottom: 1.4em;

	@media only screen and (min-width: ${desktop}) {
		font-size:80px;
	 }
		
	a{ 
		text-decoration:none;
		color:#000;
		position:relative;
		z-index:88;

		&:before {
			content: '';
			position: absolute;
			left:50%;
			top:50%;
			transform: translateY(-50%) translateX(-50%);
			z-index: 99;
			
			max-width: 160px;
			max-height: 230px;
			width: 100%;
			height:160%;
			

			@media only screen and (max-width: ${tablet}) {
				background:none !important;
			 }
			
		}

        &:hover {
            opacity: 0.3;
        }

		&:hover:before {
			z-index:-1;
			filter: url('#duotone_peachypink');
		}

		&.artist-armin-van-buuren:before {
			background: url(${armin});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top center;
		}

		&.artist-adam-beyer:before {
			background: url(${adamBeyer});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:center;
		}

		&.artist-amelie-lens:before {
			background: url(${amelielens});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:center;
		}

		&.artist-dj-paulette:before {
			background: url(${djpaulette});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:center;
		}

		&.artist-real-lies:before {
			background: url(${reallies});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:center;
		}

		&.artist-chris-liebing:before {
			background: url(${chrisLiebing});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:center;
			max-width:265px;
		}

		&.artist-eats-everything:before {
			background: url(${eatsEverything});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:center;
			max-width:300px;
		}

		&.artist-erick-morillo:before {
			background: url(${erickMorillo});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width:175px;
		}

		&.artist-john-digweed:before {
			background: url(${johnDigweed});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:center;
			max-width:220px;
			max-height:220px;
		}

		&.artist-joris-voorn:before {
			background: url(${jorisVoorn});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
		}

		&.artist-mark-brown:before {
			background: url(${markBrown});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width:165px;
		}

		&.artist-nicole-moudaber:before {
			background: url(${nicoleMoudaber});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width:240px;
			max-height:220px;
		}

		&.artist-absolute:before {
			background: url(${absolute});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width:240px;
			max-height:220px;
		}

		&.artist-paul-oakenfold:before {
			background: url(${paulOakenfold});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width: 240px;
			max-height: 176px;
		
		}

		&.artist-spinnin-sessions:before {
			background: url(${spinnin});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width: 240px;
			max-height: 176px;
		
		}

		&.artist-ultra-music-festival:before {
			background: url(${ultra});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width: 240px;
			max-height: 176px;
		
		}

		&.artist-sander-van-doorn:before {
			background: url(${sanderDoorn});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:center;
			max-width:200px;
		}

		&.artist-tiesto:before {
			background: url(${tiesto});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width:170px;
		}

		&.artist-dj-ez:before {
			background: url(${djez});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width:170px;
		}

		&.artist-jamie-jones:before {
			background: url(${jamiejones});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width:170px;
		}

		&.artist-musical-freedom:before {
			background: url(${musicalfreedom});
			background-size:100%;
			background-repeat: no-repeat;
			background-position:top;
			max-width:240px;
			max-height:220px;
			margin-top:20px;
		}

	}

	@media only screen and (max-width: ${tablet}) {
       font-size:40px;
    }
  }

  
   
`


const Title = styled.h1`
	font-size: 100px;
	line-height:1em;
    letter-spacing:0.01em;
	margin:0;
    margin-bottom:1em;
    margin-top:-1em;
	font-weight:normal;
	text-align:center;

	@media only screen and (max-width: ${tablet}) {
		font-size:40px;
	 }
	

	 @media only screen and (min-width: ${desktop}) {
		font-size:100px;
	 }
`


const Inner = styled.div`

	width:100%;

    .intro {
        p {
            font-size: 30px;
            line-height: 1.5em;
            text-align:center;
            max-width: 870px;
            margin:auto;
            width: 95%;

            @media only screen and (max-width: ${desktop}) {
                font-size:30px;
             }

            @media only screen and (max-width: ${tablet}) {
                font-size:25px;
                padding: 0 20px;
             }
             
            
        }
    }
`